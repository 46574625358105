import React, { useEffect, useState } from "react";

import Layout from "../components/Layout";
import SEO from "../components/SEO";
import {
	Col,
	Row,
	Form,
	FormGroup,
	Label,
	Input,
	Button,
	FormFeedback,
	InputGroup,
	InputGroupAddon,
	InputGroupText,
} from "reactstrap";
import ContentContainer from "../components/ContentContainer";
import UserIcon from "../images/icon-user.svg";
import { Link } from "gatsby";
import AuthedRoute from "../components/AuthedRoute";
import axios from "axios";

const ResetPassword = () => {

	const [resetData, setResetData] = useState({
		email: "",
		valid: false,
		touched: false,
		status: "",
	});

	useEffect(() => {
		// eslint-disable-next-line
		const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		setResetData({ ...resetData, valid: emailRegex.test(resetData.email) });
		console.log(resetData.valid);
	}, [resetData.email]);

	const handleSubmit = async (e) => {
		e.preventDefault();
		setResetData({ ...resetData, touched: true });

		if (resetData.valid) {
			setResetData({ ...resetData, status: "SENT" });
			try {
				await axios.post(`${ process.env.GATSBY_API_BASE_URL }/api/users/resetPassword`, { email: resetData.email });
				setResetData({ ...resetData, status: "SUCCESS" });
			} catch (err) {
				console.error(err);
				setResetData({ ...resetData, status: "ERROR" });
			}
		}
	};

	return (
		<AuthedRoute inverted>
			<Layout>
				<SEO title="Reset Password"/>
				<ContentContainer half>
					<Row>
						{ resetData.status !== "SUCCESS" &&
						<Col sm="12">
							<div className="text-center mw-75 d-block mx-auto">
								<h1 className="font-weight-bold mt-5">Passwort zurücksetzen</h1>
								<p className="mt-3 color-subtext">Falls du dein Passwort vergessen hast, kannst du dir hier ein neues zuschicken lassen.</p>
							</div>
							<Form onSubmit={ e => handleSubmit(e) } className="mw-50 d-block mx-auto">
								<FormGroup>
									<Label for="email">Email</Label>
									<InputGroup>
										<InputGroupAddon addonType="prepend">
											<InputGroupText className="round-left input-group-icon">
												<img src={ UserIcon } alt="user icon"/>
											</InputGroupText>
										</InputGroupAddon>
										<Input type="email" name="email" id="email" placeholder="muster@mail.de"
											className="round-right"
											value={ resetData.email }
											valid={ resetData.touched && resetData.valid }
											invalid={ resetData.touched && !resetData.valid }
											onChange={ e => setResetData({ ...resetData, email: e.target.value }) }/>
									</InputGroup>
									<FormFeedback valid>
										Sieht gut aus!
									</FormFeedback>
									<FormFeedback>
										Irgendwas ist komisch..
									</FormFeedback>
								</FormGroup>
								{ resetData.status === "ERROR" &&
								<p className="feedback-error">{ resetData.status }</p> }
								<Button color="primary" block className="round mt-4 py-2"
										disabled={ !resetData.valid || resetData.status === "SENT" }>Passwort zurücksetzen</Button>
								<div className="d-flex justify-content-between color-subtext mt-1 px-4">
									<Link to="/signup" className="font-weight-bold">Account erstellen</Link>
									<Link to="/login">Anmelden</Link>
								</div>
							</Form>
						</Col>
						}
						{ resetData.status === "SUCCESS" &&
						<Col sm="12">
							<div className="text-center mw-75 d-block mx-auto">
								<h1 className="font-weight-bold mt-4">Neues Passwort angefordert</h1>
								<p className="mt-3 color-subtext">Du solltest in kürze eine E-Mail von uns erhalten.</p>
								<Link to="/"><Button color="primary" block className="round mt-4 py-2">Zur Startseite</Button></Link>
							</div>
						</Col>
						}
					</Row>
				</ContentContainer>
			</Layout>
		</AuthedRoute>
	);
};

export default ResetPassword;
